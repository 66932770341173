import { Box, Theme } from '@mui/material';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode[];
  max?: number;
}

const FLEX_GAP = 6;

const useStyles = createUseStyles((theme: Theme) => ({
  scrollBox: {
    '& $scrollView': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#ffffff35 transparent',
    },
    '& $scrollView::-webkit-scrollbar': {
      width: 10,
      backgroundColor: 'transparent',
    },
    '& div::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      '-webkit-box-shadow': 'none',
    },
    '& $scrollView::-webkit-scrollbar-thumb': {
      backgroundColor: '#ffffff35',
      outline: 'none',
      borderRadius: theme.shape.borderRadius,
    },
    '& .scrollbox-child': {
      paddingRight: 20,
    },
  },
  scrollView: {
    display: 'flex',
    flexDirection: 'column',
    gap: FLEX_GAP,
  },
  scrollThumbVert: {
    backgroundColor: '#00000045',
    borderRadius: theme.shape.borderRadius,
  },
  scrollHideHorizontal: {
    display: 'none',
  },
}));

const ScrollBox: FC<Props> = ({ children, max = 3 }) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);

  // on load, measure the height, set it, then render the rest
  useEffect(() => {
    const scrollBox = ref.current;
    if (!scrollBox) {
      return;
    }

    const scrollChildren = scrollBox.querySelectorAll('.scrollbox-child');

    const childHeight = Array.from(scrollChildren).reduce(
      (totalHeight: number, child: Element) =>
        totalHeight + FLEX_GAP + Number(child?.clientHeight || 0),
      0
    );

    scrollBox.style.height = `${childHeight}px`;
    setLoaded(true);
  }, []);

  return (
    <Box className={styles.scrollBox} ref={ref}>
      <Scrollbars
        renderView={(props) => <div {...props} className={styles.scrollView} />}
        renderThumbVertical={(props) => <div {...props} className={styles.scrollThumbVert}></div>}
        renderTrackHorizontal={(props) => (
          <div {...props} className={styles.scrollHideHorizontal}></div>
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className={styles.scrollHideHorizontal}></div>
        )}
        autoHide={false}
      >
        {children.map((child, index) => {
          if (index >= max && !loaded) {
            return null;
          }
          return (
            <Box key={index} className="scrollbox-child">
              {child}
            </Box>
          );
        })}
        <Box minHeight={2}></Box>
      </Scrollbars>
    </Box>
  );
};

export default ScrollBox;
