import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { get as getLocal, set as setLocal } from '@/lib/local-storage';
import { Datasource, IUserSettings } from './helpers';
import { useQuery } from 'react-query';
import { QueryKey } from '@/lib/query-client';
import { getAllDatasources } from '@/lib/services/datasource.service';
import { DatasourceModel } from '@/lib/models/datasource.model';
import useApp from '@/hooks/use-app.hook';

export const UserSettingsContext = createContext<IUserSettings>({
  usePublicInternetDatasource: false,
  enableDatasource: () => null,
  disableDatasource: () => null,
  enablePublicSearch: () => null,
  disablePublicSearch: () => null,
  datasources: new Map(),
  isLoading: false,
});

const LOCAL_STORAGE_KEY = 'user-settings';

const loadFromLocalStorage = () => {
  const settings = getLocal(LOCAL_STORAGE_KEY, true) as {
    datasources: { id: string; enabled: boolean }[];
  };
  const datasources: Map<string, Datasource> = new Map();

  if (Array.isArray(settings?.datasources)) {
    settings?.datasources?.forEach(({ id, enabled }) => {
      datasources.set(id, { model: new DatasourceModel(), enabled });
    });
  }

  return { datasources };
};

const cachedUserSettings = loadFromLocalStorage();

export function UserSettingsProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [usePublicInternetDatasource] = useState(false);
  const [datasources, setDatasources] = useState<{ map: Map<string, Datasource> }>({
    map: new Map(),
  });

  const { signedIn } = useApp();

  const { data, isLoading } = useQuery<DatasourceModel[]>(
    [QueryKey.DatasourceList],
    async () => getAllDatasources(),
    {
      enabled: signedIn,
    }
  );

  const enablePublicSearch = useCallback(() => {
    setDatasources((old) => {
      const updated = new Map(old.map);
      Array.from(updated.entries())
        .filter(([, { model }]) => model.isPublicInternet)
        .forEach(([datasourceId, { model }]) => {
          updated.set(datasourceId, { model, enabled: true });
        });

      return { map: updated };
    });
  }, []);

  const disablePublicSearch = useCallback(() => {
    setDatasources((old) => {
      const updated = new Map(old.map);
      Array.from(updated.entries())
        .filter(([, { model }]) => model.isPublicInternet)
        .forEach(([datasourceId, { model }]) => {
          updated.set(datasourceId, { model, enabled: false });
        });

      return { map: updated };
    });
  }, []);

  const enableDatasource = useCallback((datasourceId: string | string[]) => {
    setDatasources((old) => {
      const updated = new Map(old.map);

      const datasourceIds = Array.isArray(datasourceId) ? datasourceId : [datasourceId];

      datasourceIds.forEach((datasourceId) => {
        const datasource = updated.get(datasourceId);

        if (datasource) {
          datasource.enabled = true;
          updated.set(datasourceId, datasource);
        }
      });

      return { map: updated };
    });
  }, []);

  const disableDatasource = useCallback((datasourceId: string | string[]) => {
    setDatasources((old) => {
      const updated = new Map(old.map);

      const datasourceIds = Array.isArray(datasourceId) ? datasourceId : [datasourceId];

      datasourceIds.forEach((datasourceId) => {
        const datasource = updated.get(datasourceId);

        if (datasource) {
          datasource.enabled = false;
          updated.set(datasourceId, datasource);
        }
      });

      return { map: updated };
    });
  }, []);

  useEffect(() => {
    if (!Array.isArray(data)) {
      return;
    }

    setDatasources((old) => {
      const updated: Map<string, Datasource> = new Map();

      const { datasources: lsDatasources } = cachedUserSettings;

      data.forEach((datasourceModel) => {
        const datasourceId = datasourceModel.id;
        let enabled = true;

        if (old.map.has(datasourceId)) {
          enabled = !!old.map.get(datasourceId)?.enabled;
        } else {
          if (lsDatasources.has(datasourceId)) {
            enabled = !!lsDatasources.get(datasourceId)?.enabled;
          }
        }

        updated.set(datasourceId, { model: datasourceModel, enabled });
      });

      return { map: updated };
    });
  }, [data]);

  useEffect(() => {
    setLocal(LOCAL_STORAGE_KEY, {
      datasources: Array.from(datasources.map.entries()).map(([id, { enabled }]) => ({
        id,
        enabled,
      })),
    });
  }, [datasources]);

  return (
    <UserSettingsContext.Provider
      value={{
        usePublicInternetDatasource,
        enableDatasource,
        disableDatasource,
        enablePublicSearch,
        disablePublicSearch,
        datasources: datasources.map,
        isLoading,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
}
