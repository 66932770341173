import axios, { AxiosResponse } from 'axios';
import Environment from '../environment';
import { DateRangeValue, JsonObject, DtSort } from '../helpers';
import { endOfDay, startOfDay } from 'date-fns';
import { AuthProvider } from './auth.service';
import { getTimestamp } from '../date-helpers';
import { ApiResponse } from '.';

export type FindAndCountResult = { documents: JsonObject[]; total: number };

export type MongoSort = Record<string, number> | Record<string, number>[];

const axiosClient = axios.create({
  baseURL: `${Environment.SP_API_URL}/data`,
  timeout: 10000,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  config.headers.jwtTokenString = AuthProvider.token;
  return config;
});

export const findOne = async (
  collection: string,
  params: JsonObject
): Promise<JsonObject | null> => {
  return axiosClient
    .post('/action/findOne', { ...getDefaultQueryParams(), collection, ...params })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.document as JsonObject) || null);
};

export const findAll = async (
  collection: string,
  filter: JsonObject,
  skip?: number,
  limit?: number,
  sort?: MongoSort
): Promise<JsonObject[]> => {
  const params: JsonObject = { filter };

  if (typeof skip === 'number') {
    params.skip = skip;
  }

  if (typeof limit === 'number') {
    params.limit = limit;
  }

  if (sort) {
    params.sort = sort;
  }

  return axiosClient
    .post('/action/find', {
      ...getDefaultQueryParams(),
      collection,
      ...params,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.documents as JsonObject[]) || []);
};

export const findAllAndCount = async (
  collection: string,
  filter: JsonObject,
  limit: number,
  skip = 0,
  sort?: MongoSort,
  group?: string
): Promise<FindAndCountResult> => {
  const pipeline: JsonObject[] = [{ $match: filter }, { $sort: sort || { _id: -1 } }];

  if (group) {
    pipeline.push({ $group: { _id: `$${group}`, firstDocument: { $first: '$$ROOT' } } });
    pipeline.push({
      $replaceRoot: { newRoot: '$firstDocument' },
    });
  }

  pipeline.push({
    $facet: {
      metadata: [{ $count: 'total' }],
      data: [{ $skip: skip }, { $limit: limit }],
    },
  });

  return axiosClient
    .post('/action/aggregate', {
      ...getDefaultQueryParams(),
      collection,
      pipeline,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => {
      if (!response) {
        return { documents: [], total: 0 };
      }

      let documents: JsonObject[] = [];
      let total = 0;

      try {
        const { data, metadata } = (response.documents as JsonObject[])[0] as {
          data: JsonObject[];
          metadata: [{ total: number }];
        };

        if (!data?.length || !metadata?.length) {
          return { documents: [], total: 0 };
        }

        documents = data;
        total = metadata[0].total;
      } catch (err) {
        console.error('unexpected response format from aggregation query');
        console.error(err);
        console.log(response);
        return { documents: [], total: 0 };
      }

      return { documents, total };
    });
};

export const aggregate = async (
  collection: string,
  pipeline: JsonObject[]
): Promise<JsonObject[]> => {
  return axiosClient
    .post('/action/aggregate', { ...getDefaultQueryParams(), collection, pipeline })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.documents as JsonObject[]) || []);
};

export const insertOne = async (
  collection: string,
  document: JsonObject
): Promise<string | null> => {
  return axiosClient
    .post('/action/insertOne', {
      ...getDefaultQueryParams(),
      collection,
      document,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.insertedId as string) || '');
};

export const patchOne = async (
  collection: string,
  filter: JsonObject,
  data: JsonObject
): Promise<JsonObject | null> => {
  return axiosClient
    .post('/action/updateOne', {
      ...getDefaultQueryParams(),
      collection,
      filter,
      update: {
        $set: {
          ...data,
        },
      },
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => (response?.document as JsonObject) || null);
};

export const deleteOne = async (collection: string, filter: JsonObject): Promise<boolean> => {
  return axiosClient
    .post('/action/deleteOne', {
      ...getDefaultQueryParams(),
      collection,
      filter,
    })
    .then(handleResponse)
    .then((response: JsonObject | null) => response?.deletedCount === 1);
};

export const getOrgFilter = (): JsonObject => {
  return { orgId: { $oid: AuthProvider.orgId || null } };
};

type MongoDateFilter = { $gte?: number; $lte?: number };

// @todo only supports timestamp fields
export const getDateRangeFilter = (value: DateRangeValue): MongoDateFilter | null => {
  if (!value.length) {
    return null;
  }

  const filter: MongoDateFilter = {};

  if (value[0]) {
    const startTimestamp = getTimestamp(startOfDay(value[0]));
    if (typeof startTimestamp === 'number') {
      filter.$gte = startTimestamp;
    }
  }

  if (value[1]) {
    const endTimestamp = getTimestamp(endOfDay(value[1]));
    if (typeof endTimestamp === 'number') {
      filter.$gte = endTimestamp;
    }
  }

  return filter;
};

export const getSort = (dtSort: DtSort | undefined): MongoSort | null => {
  if (!dtSort) {
    return null;
  }

  const { columnName, direction } = dtSort;

  return {
    [columnName]: direction === 'asc' ? 1 : -1,
  };
};

export const getOidParam = (id: string) => ({ $oid: id });

export const getOidParams = (ids: string[]) => ids.map(getOidParam);

const getDefaultQueryParams = (): { dataSource: string; database: string } => {
  return { dataSource: Environment.ATLAS_DATASOURCE, database: Environment.ATLAS_DATABASE };
};

const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};
