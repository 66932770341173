import { object } from 'yup';
import { BaseModel } from './base.model';
import { JsonObject, UiOption } from '../helpers';

export type ShortcutInputType =
  | 'text'
  | 'textarea'
  | 'range'
  | 'switch'
  | 'select'
  | 'select-multi';

export type ShortcutValue = string | string[];

export type ShortcutMessage = {
  id: string;
  name: string;
  instructions: string;
  steps: ShortcutStep[];
  values: ShortcutValue[];
};

export type ShortcutStep = {
  name: string;
  title: string;
  description: string;
  inputType: ShortcutInputType;
  options?: UiOption[];
  required?: boolean;
};

export class ShortcutModel extends BaseModel {
  orgId: string;
  name: string;
  description: string;
  instructions: string;
  steps: ShortcutStep[];

  schema = object({});

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof ShortcutModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.description = (data.description as string) || '';
    this.instructions = (data.instructions as string) || '';
    this.steps = (data.steps as ShortcutStep[]) || [];
  }

  get message(): ShortcutMessage {
    return {
      id: this.id,
      name: this.name,
      instructions: this.instructions || this.description,
      steps: this.steps,
      values: this.steps.map(({ inputType, options = [] }) => {
        if (['select', 'switch'].includes(inputType)) {
          return options[0]?.value || '';
        }

        return '';
      }),
    };
  }
}
