import { getDateObj } from '../date-helpers';
import { JsonObject } from '../helpers';

export class NosqlDocModel {
  id: string;
  _lastChangedAt: Date | null;

  constructor(props: JsonObject = {}) {
    const data = props as Record<string, keyof NosqlDocModel>;

    this.id = (data._id as string) || (data.id as string) || '';
    this._lastChangedAt = data._lastChangedAt ? getDateObj(data._lastChangedAt) : null;
  }
}
