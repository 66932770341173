import queryClient from '../query-client';
import { DEFAULT_SERVICE_ERROR, ServiceResponse } from './index';
import { get, post } from './sp-api.service';

export type PromptServiceSettings = {
  enhanceTokenMinThreshold: number;
  enhanceTokenMaxThreshold: number;
  stopWords: string[];
};

type PromptResponse = { prompt?: string; message?: string };

const DefaultPromptSettings = {
  enhanceTokenMinThreshold: 5,
  enhanceTokenMaxThreshold: 500,
  stopWords: [],
};

export const getSettings = async (): Promise<PromptServiceSettings> => {
  try {
    const response = await get('/prompt/settings');

    if (response) {
      return response as PromptServiceSettings;
    }
  } catch (error) {
    console.error(error);
  }
  return { ...DefaultPromptSettings };
};

export const estimateNumTokens = (prompt: string) => {
  const numWords = prompt.split(' ').length;
  return numWords * 2.5; // aka 0.4 words per token
};

export const isGoodImproveCandidate = (prompt: string): boolean => {
  const { stopWords, enhanceTokenMinThreshold, enhanceTokenMaxThreshold } =
    queryClient.getQueryData<PromptServiceSettings>('prompt-settings') || DefaultPromptSettings;

  const filteredPrompt = String(prompt)
    .split(' ')
    .filter((word) => word && !stopWords.includes(String(word).toLowerCase()))
    .join(' ');

  const tokenCount = estimateNumTokens(filteredPrompt);

  return tokenCount > enhanceTokenMinThreshold && tokenCount < enhanceTokenMaxThreshold;
};

export const improvePrompt = async (
  prompt: string,
  conversationId?: string
): Promise<ServiceResponse & { prompt?: string }> => {
  try {
    const response = await post(`/prompt/enhance`, { prompt, conversationId });

    if (!response) {
      throw new Error('prompt enhance returned null');
    }

    const { message: error, prompt: enhancedPrompt } = response as PromptResponse;

    if (error) {
      return { error };
    }

    return { prompt: enhancedPrompt };
  } catch (error) {
    console.error(error);
    return { error: DEFAULT_SERVICE_ERROR };
  }
};
