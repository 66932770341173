import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as JssThemeProvider } from 'react-jss';
import Theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { AppProvider } from './contexts/app';
import { QueryClientProvider } from 'react-query';
import queryClient from './lib/query-client';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ModalProvider } from './contexts/modal';
import RouteProvider from './router/provider';
import { ThreadProvider } from './contexts/thread';
import { initDatadog } from './lib/datadog';
import Environment from './lib/environment';
import ErrorBoundary from './components/error-boundary';
import { ThreadToastProvider } from './contexts/thread-toast';
import { UserSettingsProvider } from './contexts/user-settings';

initDatadog();
console.log(`build v${APP_VERSION}-${Environment.MODE}`);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MuiThemeProvider theme={Theme}>
      <JssThemeProvider theme={Theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <ErrorBoundary>
            <AppProvider>
              <QueryClientProvider client={queryClient}>
                <ModalProvider>
                  <SnackbarProvider>
                    <ThreadToastProvider>
                      <UserSettingsProvider>
                        <ThreadProvider>
                          <RouteProvider />
                        </ThreadProvider>
                      </UserSettingsProvider>
                    </ThreadToastProvider>
                  </SnackbarProvider>
                </ModalProvider>
              </QueryClientProvider>
            </AppProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </JssThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);
