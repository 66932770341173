import { QueryFunction } from 'react-query';
import queryClient from '@/lib/query-client';
import { getOrgBranding } from './services/org.service';
import { isPortalEnabled } from './services/policy.service';
import { getSettings as getFileSettings } from './services/file.service';
import { getSettings as getPromptSettings } from './services/prompt.service';

export type AppDataQueryKey =
  | 'org-branding'
  | 'portal-policy'
  | 'file-settings'
  | 'prompt-settings';

const HOUR = 1000 * 60 * 60;
const MINUTE = 1000 * 60;

type QueryOptions = {
  cacheTime?: number; // how long before garbage collection
  staleTime?: number; // how long before refetch
  enabled?: boolean;
};

export const AppDataQueries: Map<
  AppDataQueryKey,
  { queryFn: QueryFunction; options: QueryOptions }
> = new Map();

// Organization branding
AppDataQueries.set('org-branding', {
  queryFn: getOrgBranding,
  options: {
    staleTime: HOUR * 24,
    cacheTime: HOUR * 48,
  },
});

// Portal policy
AppDataQueries.set('portal-policy', {
  queryFn: isPortalEnabled,
  options: {
    staleTime: MINUTE,
    cacheTime: MINUTE,
  },
});

// File handler settings
AppDataQueries.set('file-settings', {
  queryFn: getFileSettings,
  options: {
    staleTime: HOUR * 24,
    cacheTime: HOUR * 48,
  },
});

// Prompt service settings
AppDataQueries.set('prompt-settings', {
  queryFn: getPromptSettings,
  options: {
    staleTime: HOUR * 24,
    cacheTime: HOUR * 48,
  },
});

export const prefetchAppData = async () => {
  const prefetchQueries: Promise<void>[] = [];

  for (const [queryKey, { queryFn, options }] of AppDataQueries) {
    prefetchQueries.push(queryClient.prefetchQuery(queryKey, queryFn, options));
  }

  return Promise.all(prefetchQueries);
};

export const getAppData = <T>(queryKey: AppDataQueryKey) => queryClient.getQueryData<T>(queryKey);
