import { orderBy } from 'lodash';
import { SAFE_LIMIT } from '.';
import { Dataset, DtSort, JsonObject } from '../helpers';
import { get } from './sp-api.service';
import { DatasourceModel } from '../models/datasource.model';

export const getAllDatasources = async (): Promise<DatasourceModel[]> =>
  getDatasources(0, SAFE_LIMIT).then(({ rows }) => rows);

export const getDatasources = async (
  page: number,
  pageSize: number,
  dtSort?: DtSort
): Promise<Dataset<DatasourceModel>> => {
  const response = await get('/datasource-controller/datasource/portal');

  let rows = ((response as JsonObject[]) || []).map((data) => new DatasourceModel(data));

  // Sort
  if (dtSort) {
    const { columnName, direction } = dtSort;
    rows = orderBy(rows, [columnName], [direction]);
  }

  // Paginate
  const startIndex = page * pageSize;
  const total = rows?.length || 0;
  rows = rows.slice(startIndex, startIndex + pageSize);

  return {
    page,
    pageSize,
    rows,
    total,
  };
};
