import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import Button from '../../button';
import Modal from '..';
import useModal from '@/hooks/use-modal.hook';

interface Props {
  error: string;
}

export const AppErrorModal: FC<Props> = ({ error }) => {
  const { closeModal } = useModal();

  const handleReload = () => {
    window.location.reload();
  };
  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal preventClose>
      <DialogTitle>Application Error</DialogTitle>
      <DialogContent>{error}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="cancel" label="Close" />
        <Button onClick={handleReload} label="Reload" />
      </DialogActions>
    </Modal>
  );
};

export default AppErrorModal;
