import { Box, Drawer, Stack, Theme } from '@mui/material';
import { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ShortcutTab from './shortcut-tab';
import HistoryTab from './history-tab';
import useThread from '@/hooks/use-thread.hook';
import useApp from '@/hooks/use-app.hook';
import UserMenu from './user-menu';
import LinkBarItem from './link-bar-item';
import NewTaskButton from './new-task-button';
import UserSettingsTab from './user-settings-tab';

const LINK_BAR_WIDTH = 94;
const MAIN_BAR_WIDTH = 292;

interface Props {
  open: boolean;
  onToggleOpen: (force?: boolean) => void;
}

const useStyles = createUseStyles<string, { width: number; open: boolean }>((theme: Theme) => ({
  sidebar: ({ open, width }) => ({
    width: open ? width + LINK_BAR_WIDTH : LINK_BAR_WIDTH,
    height: '100%',
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  drawer: ({ open, width }) => ({
    width: open ? width + LINK_BAR_WIDTH : LINK_BAR_WIDTH,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    '& .MuiPaper-root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      border: 'none',
    },
  }),
  linkBar: {
    width: LINK_BAR_WIDTH,
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: '#262626',
    color: theme.palette.common.white,
  },
  mainBar: ({ width, open }) => ({
    width: open ? width : 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxSizing: 'border-box',
  }),
  mainBarInner: ({ open }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: '20px 0px 12px 0px',
    backgroundColor: '#ededed',
    opacity: open ? 1 : 0,
    transition: theme.transitions.create('opacity', {
      delay: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
      duration: 10,
    }),
  }),
  logo: {
    position: 'absolute',
    top: 20,
    left: LINK_BAR_WIDTH + 20,
    zIndex: theme.zIndex.drawer + 10,
    width: 'auto',
    overflow: 'hidden',
  },
}));

const AppSidebar: FC<Props> = ({ open, onToggleOpen }) => {
  const styles = useStyles({
    width: MAIN_BAR_WIDTH,
    open,
  });
  const [tab, setTab] = useState<'shortcuts' | 'history' | 'user-settings'>('history');
  const { loadThread } = useThread();
  const { signedIn } = useApp();

  const handleToggleOpen = () => {
    onToggleOpen();
  };

  const handleNewThread = () => {
    loadThread();
  };

  const handleOpenShortcuts = () => {
    onToggleOpen(true);
    setTab('shortcuts');
  };

  const handleOpenHistory = () => {
    onToggleOpen(true);
    setTab('history');
  };

  const handleOpenUserSettings = () => {
    onToggleOpen(true);
    setTab('user-settings');
  };

  const activeTab = open ? tab : '';

  return (
    <Box className={styles.sidebar}>
      <Drawer open={true} className={styles.drawer} variant="permanent" anchor="left">
        <Stack className={styles.linkBar} width="100%" alignItems="center" justifyContent="center">
          <LinkBarItem icon={open ? 'chevron-left' : 'chevron-right'} onClick={handleToggleOpen} />

          <LinkBarItem
            icon="history"
            label="History"
            onClick={handleOpenHistory}
            active={activeTab === 'history'}
          />

          <LinkBarItem
            icon="shortcut"
            label="Shortcuts"
            onClick={handleOpenShortcuts}
            active={activeTab === 'shortcuts'}
          />

          <LinkBarItem
            icon="settings"
            label="Settings"
            onClick={handleOpenUserSettings}
            active={activeTab === 'user-settings'}
            border
          />

          <Box mt={1}>
            <NewTaskButton onClick={handleNewThread} />
          </Box>

          <Stack flexGrow={1} justifyContent="flex-end" pb={2}>
            {signedIn && <UserMenu />}
          </Stack>
        </Stack>

        <Box className={styles.mainBar}>
          <Box className={styles.mainBarInner}>
            {activeTab === 'shortcuts' && <ShortcutTab />}
            {activeTab === 'history' && <HistoryTab />}
            {activeTab === 'user-settings' && <UserSettingsTab />}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default AppSidebar;
